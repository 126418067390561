import React from 'react'
import Details from '../Components/PropertyDetailsPage/Details'



const PropertyDetailsPage = () => {
  return (
    <>
      <Details />
    </>

  )
}

export default PropertyDetailsPage