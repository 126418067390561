
export const locationSuggestions = [
    'Anandapuri', 'Hooghly', 'Rajarhat Chowmatha Kol', 'Chingrighata', 'Purbachal Kalitala',
    'Vip Road', 'P Majumder Road', 'Hatiara', 'Kudghat', 'Block C, Bangur', 'Daspara',
    'Joka Metro', 'Shanti Pally', 'Seal Lane', 'Gorabazar', 'Fartabad', 'Paschim Barisha',
    'Garpar', 'Sreerampur', 'Basunagar', 'Shalimar', 'Ballygunge', 'Bangur Avenue', 'Doltala',
    'Gitanjali Metro Station', 'Sakher Bazar', 'Jessore Road', 'Maniktala', 'Srirampur',
    'Sector 3 Salt Lake', 'Srinagar', 'Lake Town', 'Prafulla Kanan', 'Nimta', 'Jawpur',
    'Mahish Bathan', 'Milk Colony', 'Thakurpukur', 'Telipukur', 'Subhash Nagar', 'Atghara',
    'Shakespeare Sarani', 'Mahamayatala', 'Arabinda Pally', 'Phoolbagan', 'Dover Terrace',
    'Bagha Jatin', 'Rowdon Street', 'Bangur', 'Narkeldanga', 'Paddapukur', 'Natunpara',
    'Near Southern Avenue And Saratbose Road Crossing', 'Hindustan Park', 'Purbachal',
    'Park Circus', 'New Town Action Area Iid', 'Bhatpara', 'Vip Haldiram', 'Kasba',
    'Chinar Park', 'New Garia', 'Badam Talla', 'Manoharpukur', 'Maula Ali', 'Em Bypass',
    'Subodh Garden', 'Bijoygarh', 'Joka', 'Entally', 'Rishra', 'Cossipore', 'Action Area 2',
    'Madurdaha', 'Hb Town', 'Mission Pally', 'Bidhan Pally', 'Golf Club Road', 'Paschim Putiary',
    'Kestopur', 'New Town', 'Sodepur', 'Dakshin Raipur', 'Diamond Harbour Road', 'Ichapore',
    'Samarpally', 'Madhyamgram', 'South Sinthee', 'Zarda Bagan', 'Nona Chandanpukur',
    'Madhyamgram, Doltala', 'Uttarpara', 'New Town Action Area 1A', 'New Santoshpur',
    'Em Bypass Extension', 'Barasat', 'Hatiara Extention', 'Michael Nagar, Birati', 'Airport',
    'East Park', 'Moore Avenue', 'Dr Rajendra Road', 'Rekjuani', 'Behala Chowrasta', 'Tollygunge',
    'Kalyani', 'Shyamnagar', 'Michael Nagar', 'Friends Eleven', 'Rajarhat Main Road', 'Nabapally',
    'Sinthi', '122/2/2E Sn Sarani. Kolkata 700026', 'Action Area 2B', 'Dankuni', 'Gariahat',
    'Behala Shakuntala Park', 'Picnic Garden', 'Rajabasanta Roy Road', 'Purba Sinthi',
    'New Town Action Area 2B', 'Agarpara', 'Ariadaha', 'Chak Garia', 'Behala Manton', 'Ekdalia',
    'Near Rabindra Sarovar Metro Tollygung Road', 'Kalitala', 'Narayanpur', 'Ca Block',
    'Surer Math', 'Amrabati', 'Auckland Square', 'Rajarhat Kolkata East', 'Dhalai Bridge',
    'Beleghata', 'Alipore', 'Harinavi', 'Garia Gardens', 'Satgachi', 'Subhas Sarobar Park',
    'Chinsurah', 'Dum Dum Metro', 'Minto Park', 'Gora Bazar', 'Mandevilla Garden', 'Noapara',
    'Natunbazar.Dumdum Cantonment', 'Lansdown', 'New Town Action Area 1', 'Vip Haldirams',
    'Patuli', 'Hazra', 'Action Area 2 B', 'Action Area 2 New Town', 'Narayantala', 'Action Area 3',
    'Jogendra Garden', 'Panditiya Road', 'Bally', 'Chandannagar', 'Desopriya Park', 'Camac Street',
    'Prince Anwar Shah Road Connector', 'Ashok Nagar', 'Rabindra Nagar', 'Muchipara', 'Ruby Park East',
    'Santragachi', 'Bosepukur', 'Kalindi', 'Mukundapur', 'Sarsuna', 'New Ballygunge',
    'Sarada Park', 'Uniworld City', 'Ballygunge Gardens', 'Rathtala', 'Mission Bazar', 'Andul Road',
    'Bt Collage, Jessore Road', 'Nandi Bagan', 'Rajarhat', 'Ca.. Block', 'Kalikapur', 'Shobhabazar',
    'Ravindra Pally', 'Nayabad', 'Kadapara', 'Tara Park', 'Amarpalli', 'Hastings', 'Mayfair Road',
    'Paikpara', 'Dakshin Jagatdal', 'Tram Depot', 'Howrah', 'Bhawanipore', 'Bansdroni Park',
    'New Town Action 3', 'Ballygunge Circular Road', 'Jheel Bagan', 'Narendrapur', 'Action Area 1',
    'Deshpriya Nagar', 'Dum Dum Cantt', 'Majarhati', 'Haridevpur', 'Kumrakhali', 'Anandapur',
    'North Purbachal', 'Kankurgachi', 'Jayrampur Post Office, Behala Chourasta', 'Ganguly Bagan',
    'Girish Park', 'Biman Nagar', 'Parnashree', 'Sinthee', 'Barrackpore', 'Ultadanga', 'Bonhooghly',
    'Ho Chi Minh Sarani', 'Postal Park', 'Bara Nagar', 'Bhangar', 'Jessore Road Ganga Nagar',
    'Panchasayar', 'Barhans', 'Sector 2 Salt Lake', 'Raghunathpur', 'Thakurpukur Bazar',
    'Belgachia', 'Hussainpur', 'Nandan Kanan', 'Sodepure', 'Shyam Bazaar', 'Briji',
    'Rajathat Chowmatha', 'Sunny Park', 'Uttarpara Kotrung', 'Naipukur', 'Hanapara',
    'Garia Station', 'Purbalok', 'Budge Budge Road', 'Ranikuthi', 'Naktala', 'Makhla', 'Teghoria',
    'Bl Saha Road', 'Lake Market', 'Rabindra Sarova Metro', 'Niranjan Pally', 'South Bankimpally',
    'Vivekananda Park', 'Jodhpur Garden', 'Ramgarh', 'Dunlop', 'Tagore Park', 'Dum Dum Park',
    'P.A Shah Connector', 'Behala Thana', 'Rajdanga', 'Be... Block', 'Garcha', 'Garia',
    'Atlas More, Narendrapur', 'Metropolitan', 'Panihati', 'Kalyani A Block', 'Kaikhali',
    'New Town Biswa Bangla Gate', 'Action Area 2C', 'New Town Action Area 1D', 'Baruipur',
    'Bondal Gate', 'Sarkarhat', 'Khanna', 'Bansdroni', 'Bablatala', 'Rajpur', 'Rowland Row',
    'Tangra', 'Bakultala', 'Fern Road', 'New Town Action Area 2D', 'Rb Connector', 'Shyamnagar 24 Pgs North',
    'Raja Sc Mallick Road', 'Sinthi More', 'Laskarpur', 'Ramlal Bazaar', 'Dum Dum', 'Bd Block',
    'Kalindi Jessore Road', 'Konnagar', 'Swiss Park', 'Naihati', 'Kanchrapara', 'Golf Garden',
    'Brahmapur Christian Para', 'Mourigram', 'Batanagar', 'New Town Action Area 1C', 'Nsc Bose Road',
    'Dakshinpara', 'Survey Park', 'New Town Action Area 1B', 'Omarhati', 'Gostotala New Scheme',
    'Chakraberia', 'Dakshineswar', '39/A/2, Sri Ram Thakur Road. Kolkata 700032', 'Bandel',
    'Jodhpur Park', 'Aa 1D Newtown', 'Baghajatin Place', 'Keyatala', 'Jyangra', 'Kamalgazi',
    'Golpark', 'Sabedabagan', 'Khardah', 'Sarat Bose Road', 'Shova Bazar', 'Ballygunge Place',
    'Kalipark', 'Dash Drone', 'Lake Gardens', 'Ajoy Nagar', 'Jadavpur', 'Belur', 'Seth Bagan',
    'Birati', 'Badamtala', 'Salt Lake', 'Mandirtala', 'Ananya', 'Thakurpukur 3A Bus Stand',
    'Belgharia', 'Amtala', 'Haltu', 'Sector 5 Salt Lake', 'Kasba Siemens', 'Dhandevi Khanna Road',
    'Mankundu', 'Sealdah', 'East Kolkata Twp', 'Purba Barisha', 'Prince Anwar Shah Rd', 'Tali Park',
    'Sukchar', 'Sammilani Park', 'Sirity', 'Bhadrakali', 'City Centre 2', 'Bakul Bagan',
    'Azadgarh', 'Ruby Hosp Main Road', 'New Barrakpur', 'Budherhat', 'Tiljala', 'Usha Park',
    'On Embypass', 'Nalta', 'Rajpur Sonarpur', 'Behala Unique Park', 'Golf Green', 'Tollygunge Karunamoyee',
    'Baishnabghata Bye Lane', 'Parnasree Palli', 'Sonarpur', 'Near Southern Avenue', 'Hindustha Road',
    'Kumorpara', 'Chetla', 'Vivekananda Nagar', 'New Alipore', 'Debpukur', 'Baguihati', 'Hindmotor',
    'Lake Range', 'Southend Park', 'Harish Mukherjee Road', 'Chotto Chandpur', 'Serampore',
    'Boral', 'Diamond Park', 'Safui Para', 'Topsia', 'Kalua', 'Sector 1 Salt Lake', 'Kamalgachhi More',
    'Durganagar', 'Silpara', 'Barat Colony', 'Santoshpur', 'Subodh Park', 'Baguihati Jyangra',
    'Dashadrone', 'East Topsia', 'Kabardanga', 'New Town Actuon Area 2D', 'Maheshtala',
    'Dhakuria', '5/1B Mm Feeder Road.Kolkata-700057', 'Theatre Road', 'Sahapur', 'Kamalgachi',
    'Dhamaitala', 'Rash Behari', 'Shibpur', 'Kalighat', 'Bt Road', 'Baishnabghata',
    'Southern Avenue', 'Narendrapur Sonapur Crossing', 'Sucheta Nagar', 'Pipulpati, Chinsurah',
    'Behala', 'Bypass Road', 'Brahmapur', 'Shyam Nagar', 'Hindustan More', 'Liluah',
    'Lake Town/Kalindi', 'Madhyamgram Digberia', 'Ajc Bose Road', 'Tetultala', 'Deshpriya Park',
    'Nager Bazar', 'Raipur', 'Baguiati', 'Selimpur', 'Sn Roy Road', 'Hridaypur', 'Tobin Road',
    'Christopher Road', 'Krishnapur', 'Tollygunge Phari', 'Khalisha Kota', 'Beliaghata',
    'Ambuja Udita, Survey Park,Santoshpur'
]
