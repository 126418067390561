import React from 'react'
import Prediction from '../Components/Prediction/Prediction'

const PredictionPage = () => {
    return (
        <>
            <Prediction/>
         
      </>
    
  )
}

export default PredictionPage